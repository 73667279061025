<template>
  <div class="login_content">
    <div class="login_form">
      <h1>登 录</h1>
      <el-form
        class="form"
        ref="loginForm"
        :model="loginForm"
        :rules="loginRules">
        <el-form-item prop="mobile">
          <el-input
            type="text"
            :maxlength="11"
            v-model.trim="loginForm.mobile"
            placeholder="手机号">
          </el-input>
        </el-form-item>
        <el-form-item prop="password" @keyup.native.enter="handleLogin">
          <el-input
            type="password" show-password
            v-model.trim="loginForm.password"
            placeholder="密码">
          </el-input>
        </el-form-item>
      </el-form>
      <el-button style="width:100%;margin-top:18px" type="primary" :loading="btnLoading" @click="handleLogin">
        登 录
      </el-button>
      <div class="form_footer">
        <div class="footer_left">
          没有账号？<span class="color_active_light" @click="$emit('changeShowForm', 'generalForm')">快速注册</span>
        </div>
        <div class="footer_right color_active_primary" @click="$emit('changeShowForm', 'resetPasswordForm')">
          忘记密码
        </div>
      </div>

      <div class="third_party">
        <p class="title">第三方账号登陆</p>
        <div class="iconfont_box">
          <i class="iconfont iconweixin-copy" @click="handleTpaLogin('wechat')"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SHA256 from 'crypto-js/sha256'
import * as api from '@/api'
import cookie from '@/configs/cookie'

export default {
  data () {
    return {
      btnLoading: false, // 登录的button  loading
      // 登录信息
      loginForm: {
        mobile: '', // 目前为手机号
        password: '' // 密码
      },
      // 登录表单验证规则
      loginRules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1\d{10}$/, message: '手机号格式错误', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { pattern: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d]{8,16}$/, message: '密码长度为8~16位，至少需要包含数字、字母两种类型', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    // 官网地址
    homePath () {
      return process.env.VUE_APP_HOME_PATH
    }
  },
  methods: {
    // 进行登录
    handleLogin () {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.btnLoading = true
          api.login({
            mobile: this.loginForm.mobile,
            password: SHA256(this.loginForm.password).toString()
          }).then(res => {
            this.btnLoading = false
            if (res.data.code === 0) {
              cookie.set(this.globalVar.WEB_TOKEN, res.data.data.token)
              this.$store.commit('setUserInfo', res.data.data.user)
              // 生产模式才跳转门户
              if (process.NODe_ENV === 'production') {
                window.location.href = this.homePath
              } else {
                this.$router.push('/')
              }
            } else {
              this.$message.error(res.data.message)
            }
          }).catch((error) => {
            this.btnLoading = false
            console.log(error)
            this.$message.error('请求出错')
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 三方登录
    handleTpaLogin (type) {
      const { origin } = location
      // 登录后要么跳到当前带着的回跳，要么是控制台
      api.getThirdPartyUrl(type, {
        redirect: `${origin}/third-party-callback/${type}/authorize`
      }).then(res => {
        if (res.data.code === 0) {
          console.log('获取第三方登录地址', res.data.data)
          window.open(res.data.data.url, '_blank')
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .login_content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1100px;
    height: 640px;
    padding-left: 550px;
    box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.23);
    border-radius: 10px;
    background:url('../../../assets/images/login/login_box_left_bg.png') left/550px 640px no-repeat,
                url('../../../assets/images/login/login_box_right_bottom.png') right bottom/241px 127px no-repeat;
    background-color: #ffffff;
    overflow: hidden;
    .login_form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 60px 95px;
      & > h1 {
        font-size: 30px;
        font-weight: bold;
        line-height: 60px;
        color: #303133;
        text-align: center;
        margin-bottom: 70px;
      }
      .form {
        width: 100%;
        .form_btn {
          width: 100%;
        }
      }
      .form_footer {
        display: flex;
        width: 100%;
        height: 30px;
        align-items: center;
        justify-content: space-between;
        padding-top: 20px;
        .footer_left, .footer_right {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #909399;
        }
      }
      .third_party {
        width: 100%;
        padding-top: 55px;
        .title {
          font-size: 14px;
          font-weight: 500;
          line-height: 30px;
          color: #909399;
          text-align: center;
        }
        .iconfont_box {
          display: flex;
          height: 36px;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin-top: 20px;
          & > i {
            font-size: 36px;
          }
          .iconweixin-copy {
            color: #58bc46;
          }
        }
      }
    }
  }
</style>
