<template>
  <div class="login_wrapper">
    <a :href="homePath" class="title_box">
      <img src="../../assets/images/logo.png" alt="">
      <h1>共创云</h1>
    </a>
    <!-- 表单 -->
    <transition name="form-move">
      <component :is="showForm" @changeShowForm="changeShowForm"></component>
    </transition>
    <!-- 底部的公司名称 -->
    <div class="footer_company">
      <!-- <a :href="adminPath" target="_blank">管理后台</a> -->
      <p>优聘科技Copyright &copy; 2015 - {{new Date().getFullYear()}} YOYOHR.COM All Rights Reserved</p>
    </div>
  </div>
</template>
<script>
import loginForm from './components/login-form'
import generalForm from './components/register/general-form'
import resetPasswordForm from './components/reset-password-form'

export default {
  data () {
    return {
      showForm: 'loginForm' // 显示的表单
    }
  },
  computed: {
    // 后台地址
    adminPath () {
      return process.env.VUE_APP_ADMIN_PATH
    },
    // 官网地址
    homePath () {
      return process.env.VUE_APP_HOME_PATH
    }
  },
  components: {
    loginForm,
    generalForm,
    resetPasswordForm
  },
  created () {
  },
  mounted () {
    this.openAlert()
  },
  methods: {
    // 打开停机公告
    openAlert () {
      this.$alert('本网站于今天17:30开始进行服务器升级，停机两小时，期间无法访问系统进行操作！对此造成的不便之处，敬请谅解！', '网站维护停机公告', {
        confirmButtonText: '确定',
        callback: (action) => {
          console.log(action)
        }
      })
    },
    // 改变所显示的表单项
    changeShowForm (form) {
      this.showForm = form
    }
  }

}
</script>

<style lang="scss" scoped>
  .login_wrapper {
    position: relative;
    min-width: 1100px;
    width: 100%;
    height: 100%;
    background-image:url('../../assets/images/login/login_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0;
    overflow: hidden;
    .title_box {
      position: absolute;
      top: 65px;
      left: 76px;
      display: flex;
      align-items: center;
      height: 50px;
      & > img {
        width: 40px;
        height: 40px;
      }
      & > h1 {
        font-size: 22px;
        margin-left: 10px;
        color: #FFFFFF;
      }
    }

    .footer_company {
      position: absolute;
      bottom: 24px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      &::before {
        content: "";
        width: 238px;
        height: 1px;
        margin-bottom: 12px;
        background-color: rgba(255, 255, 255, 0.5);
      }
      & > p, & > a {
        font-size: 12px;
        font-weight: 400;
        color: #FFFFFF;
      }
    }

    // 入场前状态
    .form-move-enter {
      transform: translate(200%, 100%) !important;
    }
    // 离场状态
    .form-move-leave-to {
      transform: translate(-200%, 100%) !important;
    }
    // 激活状态 已经正位
    .form-move-enter-active, .form-move-leave-active {
      transition: all 1s cubic-bezier(.23,1,.32,1);
    }
  }
</style>
