<template>
  <div class="form_wrapper">
    <h1>用户注册</h1>
    <div class="form_content">
      <el-form
        class="form"
        ref="form"
        :model="form"
        :rules="rules">
        <el-form-item prop="mobile">
          <el-input
            type="text"
            :maxlength="11"
            v-model.trim="form.mobile"
            placeholder="请输入手机号">
          </el-input>
        </el-form-item>
        <el-form-item prop="smsCode">
          <el-row type="flex">
            <el-input
              style="width: 260px;"
              type="text"
              v-model.trim="form.smsCode"
              placeholder="请输入短信验证码">
            </el-input>
            <el-button
              style="width: 120px;"
              type="primary"
              :loading="sendSmsLoading"
              :disabled="!(canGetSms && isMobileValid)"
              @click="handleSendSms">
              {{ sendSmsBtnText }}
            </el-button>
          </el-row>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password" show-password
            v-model.trim="form.password"
            placeholder="请输入密码">
          </el-input>
        </el-form-item>
        <el-form-item prop="confirmPassword">
          <el-input
            type="password" show-password
            v-model.trim="form.confirmPassword"
            placeholder="请确认密码">
          </el-input>
        </el-form-item>
        <el-form-item size="small">
          <el-checkbox v-model="agree">
            <p class="tw-text-14px tw-text-primary-text">已仔细阅读并同意
              <a :href="`${publicPath}平台用户协议.pdf`" target="_blank">《用户服务协议》</a>
            </p>
          </el-checkbox>
        </el-form-item>
      </el-form>
      <el-row type="flex" justify="center">
        <el-button
          :disabled="!agree"
          type="primary"
          style="width: 360px;margin-top:18px;"
          :loading="submitBtnLoading"
          @click="submitForm">
          立即注册
        </el-button>
      </el-row>
      <p class="color_active_primary" @click="$emit('changeShowForm', 'loginForm')">返回登录</p>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import SHA256 from 'crypto-js/sha256'

export default {
  data () {
    return {
      sendSmsLoading: false, // 获取短信验证码 loading
      sendSmsBtnText: '获取验证码', // 获取短信验证码按钮文字
      submitBtnLoading: false, // 注册 loading
      canGetSms: true, // 是否可以获取短信验证码
      agree: true, // 是否同意条款
      // 注册信息
      form: {
        mobile: '', // 手机号
        smsCode: '', // 验证码
        password: '', // 密码
        confirmPassword: '' // 确认密码
      },
      // 注册表单验证规则
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1\d{10}$/, message: '手机号格式错误', trigger: 'blur' }
        ],
        smsCode: [
          { required: true, message: '请输入短信验证码', trigger: 'blur' },
          { pattern: /^[0-9]{6}$/, message: '验证码格式错误', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { pattern: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d]{8,16}$/, message: '密码长度为8~16位，至少需要包含数字、字母两种类型', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '请确认密码', trigger: 'blur' },
          { pattern: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d]{8,16}$/, message: '密码长度为8~16位，至少需要包含数字、字母两种类型', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请确认密码'))
              } else if (value !== this.form.password) {
                callback(new Error('两次输入密码不一致!'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    // 对手机号进行校验
    isMobileValid () {
      return this.form.mobile && /^1\d{10}$/.test(this.form.mobile)
    },
    // 开放目录
    publicPath () {
      return process.env.BASE_URL
    }
  },
  methods: {
    /**
     * 获取短信验证码
     */
    handleSendSms () {
      if (!(this.canGetSms && this.isMobileValid)) return
      this.canGetSms = false
      this.sendSmsLoading = true
      api.sendSmsCode({
        mobile: this.form.mobile
      }).then(res => {
        this.sendSmsLoading = false
        if (res.data.code === 0) {
          let count = 30
          this.sendSmsBtnText = `已发送(${count}s)`
          const interval = setInterval(() => {
            if (count <= 0) {
              clearInterval(interval)
              this.sendSmsBtnText = '发送验证码'
              this.canGetSms = true
            } else {
              count--
              this.sendSmsBtnText = `已发送(${count}s)`
            }
          }, 1000)
        } else {
          this.canGetSms = true
          this.$message.error(res.data.message)
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求出错')
        this.canGetSms = true
        this.sendSmsLoading = false
      })
    },
    /**
     * 注册
     */
    submitForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitBtnLoading = true
          api.register({
            mobile: this.form.mobile,
            password: SHA256(this.form.password).toString(),
            smsCode: this.form.smsCode
          }).then(res => {
            this.submitBtnLoading = false
            if (res.data.code === 0) {
              this.$message.success('注册成功，请登录')
              this.$emit('changeShowForm', 'loginForm')
            } else {
              this.$message.error(res.data.message)
            }
          }).catch((error) => {
            this.submitBtnLoading = false
            console.log(error)
            this.$message.error('请求出错')
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .form_wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 640px;
    padding-left: 550px;
    box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.23);
    border-radius: 10px;
    background: url('../../../../assets/images/login/login_box_right_bottom.png') right bottom/241px 127px no-repeat;
    background-color: #ffffff;
    padding: 40px 0;
    overflow: hidden;
    & > h1 {
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      line-height: 60px;
      color: #303133;
    }
    .form_content {
      width: 360px;
      padding: 40px 0 0 0;
      margin: 0 auto;
      .form {
        width: 100%;
      }
      & > p {
        font-size: 14px;
        font-weight: 500;
        line-height: 30px;
        color: #909399;
        margin-top: 10px;
        text-align: center;
        &:hover {
          color: $color-primary;
        }
      }
    }
  }
</style>
